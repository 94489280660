<script setup lang="ts">
import { ref } from 'vue'
import { refDebounced, useDebounceFn } from '@vueuse/core'
import type { LocationQueryValue } from '#vue-router'

const route = useRoute()
const { t } = useI18n()
const toLocalePath = useLocalePath()
const { getJobSearchFilters, getIndustryOptions, getJobSearchFiltersCount } = useFilters()
const filters = getJobSearchFilters(t, true, true)
const industryOptions = getIndustryOptions(t)
const { $sentryCaptureException, $fbqTrack } = useNuxtApp()
const { gtag } = useGtag()

const emits = defineEmits(['search'])
const props = defineProps({
  syncUrl: {
    type: Boolean as PropType<boolean>,
    default: false,
    required: false
  },
  industryFilterElm: {
    type: Object as PropType<HTMLElement>,
    default: undefined,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  showFilters: {
    type: Boolean,
    default: true
  },
  showFilterTags: {
    type: Boolean,
    default: false
  },
  showHotTags: {
    type: Boolean,
    default: false
  }
})

function decodeQueryParam(queryParam: LocationQueryValue | LocationQueryValue[]): string[] {
  if (!queryParam) return []
  if (Array.isArray(queryParam)) {
    return (queryParam as string[]).map((v) => v)
  }
  return [queryParam as string]
}

// The model values of the filters
const filterValues = ref(
  Object.fromEntries(
    filters.map((filter) => [filter.name, decodeQueryParam(route.query[filter.name])])
  )
)
// The URL query object
const urlQueryObject = ref<Record<string, string[]>>({})

const hotTags = [
  'Software Engineer',
  'Frontend Developer',
  'Backend Developer',
  'Business Analyst',
  'Project Management',
  'IT Support',
  'Digital Marketing'
]

const query = ref<string | undefined>((route.query.search as string) || '')
const debouncedQuery = refDebounced(query, 300)

const attachSearchGroupTransitionClasses = ref(true)

function updateUrlQueryObject() {
  // Update the URL filter query object based on the filter model values
  const entires = Object.entries(filterValues.value)
    .filter(([, values]) => values && values.length > 0)
    .map(([name, values]) => {
      const mappedValues = values.map((v: string) => v).sort()
      return [name, mappedValues]
    })
  const queryObj = Object.fromEntries(entires)

  urlQueryObject.value = queryObj
}

const emitSearch = useDebounceFn(() => {
  // Tell parent component to execute search api
  emits('search', {
    query: query.value,
    ...urlQueryObject.value
  })
}, 300)

updateUrlQueryObject()
emitSearch()

const jobSearchSuggestions = await useJobSearchSuggestions(debouncedQuery)

const { data: countData } = await getJobSearchFiltersCount()
if (!countData) {
  $sentryCaptureException(new Error('Failed to fetch count data.'))
  throw createError({ statusCode: 500, statusMessage: 'Failed to fetch count data' })
}

const flattenCountData = computed(() => {
  const Obj: Record<string, number> = {}
  for (const count of countData) {
    if (count.industry) {
      Obj[count.industry] = count.entries
    }
  }
  return Obj
})

function emitSearchFromFilterUpdate() {
  if (process.client && props.syncUrl) {
    const newQuery = {
      search: query.value || undefined,
      ...urlQueryObject.value
    }

    // compare to the current query
    if (JSON.stringify(newQuery) === JSON.stringify(route.query)) {
      return
    }
    emitSearch()
  }
}

function setUrlQueryFromUrlQueryObject() {
  navigateTo(
    toLocalePath({
      name: 'jobs',
      query: {
        title: route.query.title || undefined,
        search: query.value || undefined,
        ...urlQueryObject.value
      }
    })
  )
}

function handleSubmit() {
  // only emit GA search event when search bar field is not empty
  if (query.value) {
    gtag('event', 'search', {
      search_term: query.value
    })
    $fbqTrack('Search', {
      search_string: query.value
    })
  }
  navigateTo(
    toLocalePath({
      name: 'jobs',
      query: {
        title: route.query.title || undefined,
        search: query.value || undefined,
        ...urlQueryObject.value
      }
    })
  )
  emitSearch()
}

function handleSelectHotTag(tag: string) {
  navigateTo(
    toLocalePath({
      name: 'jobs',
      query: {
        title: route.query.title || undefined,
        search: tag
      }
    })
  )
}

function isIndustrySelected(industry: string) {
  return filterValues.value.industry?.includes(industry)
}

/** Note: Since filterValues is watched by watcher with { deep: true },
 * from official doc, when mutating (rather than replacing) an Object/Array and watch with deep option,
 * the old value will be the same as new value because they reference the same Object/Array.
 * Vue doesn't keep a copy of the pre-mutate value. Hence, we have to re-assigning ALL the nested
 * Object/Array inside filterValues and the filterValues ITSELF for different new and old value.
 */
function handleIndustryCheckboxClick(industry: string, checked?: boolean) {
  if (!filterValues.value) return

  const newObj = { ...filterValues.value } // cloned copy
  if (!filterValues.value.industry) newObj.industry = []
  if (checked) {
    if (!filterValues.value.industry.includes(industry)) {
      const newArr = [...newObj.industry] // cloned copy
      newArr.push(industry)
      newObj.industry = newArr // re-assign
    }
  } else {
    newObj.industry = newObj.industry.filter((v) => v !== industry)
  }
  filterValues.value = newObj // re-assign
}

function handleReset() {
  filterValues.value = Object.fromEntries(filters.map((filter) => [filter.name, []]))
  query.value = undefined
}

/** Note: Since filterValues is watched by watcher with { deep: true },
 * from official doc, when mutating (rather than replacing) an Object/Array and watch with deep option,
 * the old value will be the same as new value because they reference the same Object/Array.
 * Vue doesn't keep a copy of the pre-mutate value. Hence, we have to re-assigning ALL the nested
 * Object/Array inside filterValues and the filterValues ITSELF for different new and old value.
 */
function handleRemoveFilter(tag: string) {
  const [key, value] = tag.split('-')

  if (key === 'search') {
    query.value = undefined
    handleSubmit()
  }

  if (filterValues.value[key] && filterValues.value[key].includes(value)) {
    const newObj = { ...filterValues.value } // cloned copy
    const newArr = [...newObj[key]] // cloned copy
    newArr.splice(filterValues.value[key].indexOf(value), 1)
    newObj[key] = newArr // re-assign
    filterValues.value = newObj // re-assign
  }
}

function convertValue(key: string, value: string) {
  if (key === 'industry') {
    return toJobIndustry(t, value)
  }
  if (key === 'role') {
    return toJobRole(t, value)
  }
  if (key === 'location') {
    return toJobLocation(t, value)
  }
  if (key === 'positionType') {
    return toJobEmploymentType(t, value)
  }
  if (key === 'workType') {
    return toJobWorkMode(t, value)
  }
  return value
}

function convertTag(tag: string) {
  const key = tag.split('-')[0]
  const value = decodeURIComponent(tag.split('-')[1])
  return convertValue(key, value)
}

const computedWithPrev = (cb: any) => {
  let previous: string[] = []
  return computed(() => (previous = cb(previous)))
}

const filterTags = computedWithPrev((oldValue: string[]) => {
  let tags = [...oldValue]
  // For each filter model value, add a tag to the filter tags array
  Object.entries(filterValues.value).forEach(([key, values]) => {
    if (values && values.length > 0) {
      values.forEach((value: string) => {
        const tag = key + '-' + value
        // Only add the tag if it doesn't exist
        if (!tags.includes(tag)) {
          tags.push(tag)
        }
      })
    }
  })

  tags.forEach((tag: string) => {
    const [key, value] = tag.split('-')
    if (key === 'search') return
    if (
      !filterValues.value[key] ||
      (filterValues.value[key] && !filterValues.value[key].includes(value))
    ) {
      tags = tags.filter((v) => v !== tag)
    }
  })
  // Add the search query tag
  if (query.value) {
    const tag = 'search' + '-' + query.value
    // Only add the tag if it doesn't exist
    if (!tags.includes(tag)) {
      // Remove all search tags
      tags = tags.filter((v) => !v.startsWith('search'))
      tags.push(tag)
    }
  } else {
    tags = tags.filter((v) => !v.startsWith('search'))
  }
  return tags
})

function getFiltersCount(industry: string) {
  return flattenCountData.value[industry] || 0
}

let timeout: ReturnType<typeof setTimeout> | null = null

onMounted(() => {
  attachSearchGroupTransitionClasses.value = true
  timeout = setTimeout(() => {
    attachSearchGroupTransitionClasses.value = false
  }, 100)

  // emit GA search event when first loaded and search is not empty
  if (query.value) {
    gtag('event', 'search', {
      search_term: query.value
    })
    $fbqTrack('Search', {
      search_string: query.value
    })
  }
})

onBeforeUnmount(() => {
  attachSearchGroupTransitionClasses.value = true
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
})

if (process.client) {
  // Watch filter model values and update the URL
  watch(
    filterValues,
    (newValue, oldValue) => {
      // only emit GA filter event when filter genuinely changed
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        // Filter model update -> (prepare) update URL queries
        updateUrlQueryObject()
        // Filter model update -> emit search event
        emitSearchFromFilterUpdate()
        // Actually update the URL queries
        setUrlQueryFromUrlQueryObject()
        for (const filterKey in newValue) {
          if (newValue[filterKey].length === 0) continue
          if (newValue[filterKey].length === oldValue[filterKey].length) continue
          const value = newValue[filterKey].filter((val) => !oldValue[filterKey].includes(val))[0]
          gtag('event', 'filter', {
            filter_type: filterKey,
            filter_value: convertValue(filterKey, value)
          })
        }
      }
    },
    { deep: true } // beware of deep option of watcher!
  )
  // Watch the URL queries and update the filter model values
  watch(
    () => route.query,
    (newValue, oldValue) => {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        // URL queries update -> update filter model values
        filters.forEach((filter) => {
          filterValues.value[filter.name] = decodeQueryParam(route.query[filter.name])
        })
        // Filter model update -> (prepare) update URL queries
        updateUrlQueryObject()
        // Filter model update -> emit search event
        emitSearch()
      }
    }
  )
}
</script>

<template>
  <div class="min-h-fit">
    <div>
      <JobSearchBar
        v-model="query"
        :company-suggestions="
          jobSearchSuggestions.data.value?.companyHits.map((h) => {
            return { label: h, value: h }
          })
        "
        :job-suggestions="
          jobSearchSuggestions.data.value?.jobHits.map((h) => {
            return { label: h, value: h }
          })
        "
        :keyword-suggestions="
          jobSearchSuggestions.data.value?.queryHits.map((h) => {
            return { label: h, value: h }
          })
        "
        :is-mobile="$viewport.isLessThan('lg')"
        :loading="props.loading"
        @submit="handleSubmit"
      />
    </div>
    <FormFilterGroup
      v-if="showFilters"
      v-model="filterValues"
      :filters="filters"
      :hide-keys="$viewport.isLessThan('lg') ? [] : ['industry']"
      wrapper-class="m-auto flex flex-col lg:flex-row gap-[0.62rem] mt-[1.88rem] lg:w-[55rem]"
    />
    <div
      v-if="showFilterTags"
      class="m-auto mt-[0.62rem] flex min-h-[30px] flex-wrap items-start gap-[0.625rem] lg:w-[55rem]"
    >
      <div
        v-for="(tag, i) in filterTags"
        :key="i"
        class="flex flex-row items-center gap-[0.31rem] rounded-[0.3125rem] bg-secondary-1 px-[0.62rem] py-[0.31rem]"
      >
        <div class="text-t6 font-light">{{ convertTag(tag) }}</div>
        <IconsFigmaCloseOutlined
          class="h-[1rem] w-[1rem] cursor-pointer"
          @click="handleRemoveFilter(tag)"
        />
      </div>
      <div
        v-if="filterTags.length > 0"
        class="flex cursor-pointer flex-row items-center gap-[0.31rem] px-[0.62rem] py-[0.31rem]"
        @click="handleReset"
      >
        <IconsFigmaResetOutlined class="h-[1rem] w-[1rem]" />
        <div>{{ $t('jobBoard.filter.resetFilter') }}</div>
      </div>
    </div>
    <div
      v-if="showHotTags"
      class="m-auto mt-[0.62rem] flex flex-wrap items-start justify-center gap-[0.625rem]"
    >
      <div
        v-for="(tag, i) in hotTags"
        :key="i"
        class="flex cursor-pointer flex-row items-center gap-[0.31rem] rounded-[0.3125rem] bg-secondary-1 px-[0.62rem] py-[0.31rem] transition-colors duration-100 ease-in hover:bg-cyan-30"
        @click="handleSelectHotTag(tag)"
      >
        <div class="text-t6 font-light">{{ tag }}</div>
      </div>
    </div>
    <ClientOnly>
      <Teleport v-if="industryFilterElm" :to="industryFilterElm">
        <FormKit
          v-for="industry in industryOptions"
          :key="industry.value"
          type="checkbox"
          :label="`${industry.label} (${getFiltersCount(industry.value)})`"
          :name="industry.value"
          :model-value="isIndustrySelected(industry.value)"
          @update:model-value="(checked: boolean | undefined) => handleIndustryCheckboxClick(industry.value, checked)"
        />
      </Teleport>
    </ClientOnly>
  </div>
</template>
