<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" :class="mergedClasses">
    <mask
      id="mask0_3704_3661"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="19"
      height="18"
    >
      <rect x="0.5" width="18" height="18" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_3704_3661)">
      <path
        d="M9.5 15C7.825 15 6.40625 14.4188 5.24375 13.2563C4.08125 12.0938 3.5 10.675 3.5 9C3.5 7.325 4.08125 5.90625 5.24375 4.74375C6.40625 3.58125 7.825 3 9.5 3C10.3625 3 11.1875 3.17813 11.975 3.53438C12.7625 3.89063 13.4375 4.4 14 5.0625V3.75C14 3.5375 14.0719 3.35937 14.2156 3.21562C14.3594 3.07187 14.5375 3 14.75 3C14.9625 3 15.1406 3.07187 15.2844 3.21562C15.4281 3.35937 15.5 3.5375 15.5 3.75V7.5C15.5 7.7125 15.4281 7.89063 15.2844 8.03438C15.1406 8.17813 14.9625 8.25 14.75 8.25H11C10.7875 8.25 10.6094 8.17813 10.4656 8.03438C10.3219 7.89063 10.25 7.7125 10.25 7.5C10.25 7.2875 10.3219 7.10937 10.4656 6.96562C10.6094 6.82187 10.7875 6.75 11 6.75H13.4C13 6.05 12.4531 5.5 11.7594 5.1C11.0656 4.7 10.3125 4.5 9.5 4.5C8.25 4.5 7.1875 4.9375 6.3125 5.8125C5.4375 6.6875 5 7.75 5 9C5 10.25 5.4375 11.3125 6.3125 12.1875C7.1875 13.0625 8.25 13.5 9.5 13.5C10.35 13.5 11.1281 13.2844 11.8344 12.8531C12.5406 12.4219 13.0875 11.8437 13.475 11.1187C13.575 10.9437 13.7156 10.8219 13.8969 10.7531C14.0781 10.6844 14.2625 10.6812 14.45 10.7437C14.65 10.8062 14.7938 10.9375 14.8813 11.1375C14.9688 11.3375 14.9625 11.525 14.8625 11.7C14.35 12.7 13.6188 13.5 12.6688 14.1C11.7188 14.7 10.6625 15 9.5 15Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
