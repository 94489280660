<script setup lang="ts">
const props = defineProps({
  isMobile: {
    // Whether the search bar is mobile
    type: Boolean as PropType<boolean>,
    default: false
  },
  modelValue: {
    // The value of the search input
    type: String as PropType<string>,
    default: ''
  },
  loading: {
    // Whether the button is loading
    type: Boolean as PropType<boolean>,
    default: false
  },
  keywordSuggestions: {
    type: Array as PropType<{ label: string; value: string }[]>,
    default: () => []
  },
  jobSuggestions: {
    type: Array as PropType<{ label: string; value: string }[]>,
    default: () => []
  },
  companySuggestions: {
    type: Array as PropType<{ label: string; value: string }[]>,
    default: () => []
  }
})

const existInKeywords = computed(() => {
  return props.keywordSuggestions.some(
    (suggestion) => suggestion.value.toLocaleLowerCase() === props.modelValue.toLocaleLowerCase()
  )
})

const emit = defineEmits(['update:modelValue', 'submit'])

function handleSelect(value: string) {
  emit('update:modelValue', value)
  emit('submit')
}

function handleSearchInput(event: any) {
  emit('update:modelValue', event.target.value)
}
</script>

<template>
  <ComboboxRoot
    :model-value="props.modelValue"
    :filter-function="(list, _) => list"
    @update:model-value="handleSelect"
  >
    <div
      class="relative m-auto h-[3.75rem] w-full items-center rounded-search py-[0.75rem] pl-[1.25rem] pr-[0.75rem] ring-1 ring-primary transition-shadow duration-300 ease-in focus-within:ring-2 focus-within:ring-blue-40 dark:bg-gray-60 lg:h-[4.0625rem] lg:max-w-[46.875rem] lg:pl-[1.5rem] lg:pr-[0.75rem]"
    >
      <div class="m-auto flex h-full">
        <ComboboxInput
          class="job-search-input placeholder-font-medium w-full rounded-none !p-0 text-h5 font-medium text-primary placeholder-gray-50 focus-visible:outline-0"
          :placeholder="$t('jobBoard.indexPage.search.placeholder')"
          @input="handleSearchInput"
        />
        <div class="absolute inset-y-0 right-0 flex items-center pr-2">
          <ButtonPurpleButton
            :size="!props.isMobile ? 'medium' : 'normal'"
            variant="normal"
            :loading="loading"
            :height-override="!props.isMobile ? '2.8125rem !important' : '2.25rem !important'"
            :width-override="!props.isMobile ? '9.375rem !important' : undefined"
            :aria-label="$t('jobBoard.indexPage.search.label')"
            @click="$emit('submit')"
          >
            {{ !props.isMobile ? $t('jobBoard.indexPage.search.label') : '' }}
            <template #left-icon>
              <IconsFigmaSearchOutlined />
            </template>
          </ButtonPurpleButton>
        </div>
      </div>
      <ComboboxContent
        v-if="
          props.modelValue ||
          props.keywordSuggestions.length > 0 ||
          props.companySuggestions.length > 0
        "
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade absolute z-10 mt-1 w-[80vw] overflow-auto rounded-menu bg-white p-[0.625rem] font-light shadow-menu ring-0 transition-opacity duration-150 ease-in will-change-[opacity,transform] focus:outline-none dark:bg-gray-60 lg:w-[40rem]"
      >
        <ComboboxViewport>
          <ComboboxItem
            v-if="props.modelValue && !existInKeywords"
            :key="props.modelValue"
            :value="props.modelValue"
            class="relative flex cursor-pointer select-none flex-row items-center gap-2 rounded-md px-4 py-2 data-[highlighted]:bg-primary data-[highlighted]:text-white"
          >
            <div class="h-5 w-5">
              <IconsFigmaSearchOutlined />
            </div>
            <div class="flex items-center gap-2">
              {{ props.modelValue }}
            </div>
          </ComboboxItem>
          <ComboboxItem
            v-for="suggestion in keywordSuggestions"
            :key="suggestion.value"
            :value="suggestion.value"
            class="relative flex cursor-pointer select-none flex-row items-center gap-2 rounded-md px-4 py-2 data-[highlighted]:bg-primary data-[highlighted]:text-white"
          >
            <div class="h-5 w-5">
              <IconsFigmaSearchOutlined />
            </div>
            <div class="flex items-center gap-2">
              {{ suggestion.label }}
            </div>
          </ComboboxItem>

          <ComboboxGroup>
            <ComboboxLabel class="rounded-md bg-gray-30 px-4 py-2 text-t5 font-medium text-primary">
              {{ $t('jobBoard.jobPage.jobs') }}
            </ComboboxLabel>
            <ComboboxItem
              v-for="suggestion in jobSuggestions"
              :key="suggestion.value"
              :value="suggestion.value"
              class="relative cursor-pointer select-none rounded-md px-4 py-2 data-[highlighted]:bg-primary data-[highlighted]:text-white"
            >
              <span class="flex items-center gap-2">
                {{ suggestion.label }}
              </span>
            </ComboboxItem>
          </ComboboxGroup>
          <ComboboxGroup>
            <ComboboxLabel class="rounded-md bg-gray-30 px-4 py-2 text-t5 font-medium text-primary">
              {{ $t('jobBoard.jobPage.companies') }}
            </ComboboxLabel>
            <ComboboxItem
              v-for="suggestion in companySuggestions"
              :key="suggestion.value"
              :value="suggestion.value"
              class="relative cursor-pointer select-none rounded-md px-4 py-2 data-[highlighted]:bg-primary data-[highlighted]:text-white"
            >
              <span class="flex items-center gap-2">
                {{ suggestion.label }}
              </span>
            </ComboboxItem>
          </ComboboxGroup>
        </ComboboxViewport>
      </ComboboxContent>
    </div>
  </ComboboxRoot>
</template>
