<script setup lang="ts">
import type { FormKitGroupValue } from '@formkit/core'
import type { TLSelectOption } from '../../../types/formkit-custom'
export type TFilter = {
  label: string
  placeholder: string
  name: string
  multiple: boolean
  searchable: boolean
  itemOptions: TLSelectOption[]
}

const props = defineProps({
  filters: {
    type: Object as PropType<TFilter[]>,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  wrapperClass: {
    type: String,
    default: 'mb-4'
  },
  loading: {
    type: Boolean,
    default: false
  },
  hideKeys: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue'])
function handleInput(value: FormKitGroupValue | undefined) {
  emit('update:modelValue', value)
}
</script>

<template>
  <div :class="wrapperClass">
    <FormKit type="group" :model-value="props.modelValue" @update:model-value="handleInput">
      <template v-for="filter in props.filters" :key="filter.name">
        <div v-if="!hideKeys.includes(filter.name)" class="m-auto w-full">
          <FormKit
            :classes="{
              outer: '$remove:mb-4 $remove:formkit-disabled:opacity-50',
              inner: 'font-light text-t5 text-primary',
              wrapper: '$remove:max-w-md'
            }"
            input-cls="font-light text-t5 text-primary"
            type="tlSelectV2"
            :multiple="filter.multiple"
            :searchable="filter.searchable"
            :name="filter.name"
            :item-options="filter.itemOptions"
            :floating-label="true"
            :placeholder="filter.placeholder"
            :label="filter.label"
            :show-label="false"
            :disabled="props.loading"
            :loading="props.loading"
            :sections-schema="{
              label: { $el: null, children: '' }
            }"
            drop-down-width-override="300"
          >
          </FormKit>
        </div>
      </template>
    </FormKit>
  </div>
</template>
./FormKitSelect.vue
